@use '../../styles/vars';

.error {
  background-color: vars.$mainColor;
  min-height: 80vh;



  @media (max-width: 425px) {
    min-height: 40vh;
    ;
  }

  &__gif {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--img {
      width: 100%;
      // height: 100vh;

      @media (max-width: 425px) {
        height: 100%;
        ;
      }


    }
  }


}