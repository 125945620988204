@use '../../../styles/vars';

.chooseStackBackground {
  z-index: 3;
  background-color: rgb(0, 0, 0, 0.7);
  height: 200vh;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dark {
  &.chooseStackBackground {
    background-color: rgb(0, 0, 0, 0.4);
  }
}

.chooseStackContainer {
  // position: fixed;
  margin-top: 15rem;
  z-index: 3;
  background-color: vars.$mainBackground;
  border-radius: 0.8rem;
  width: 45rem;
  // width: 45rem;
  // height: 30rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1.5rem 2.5rem;

  @media (max-width: 665px) {
    padding: 1.5rem 0.5rem;
    flex-direction: column;
    align-items: center;
  }

  &--title {
    width: 90%;
    color: vars.$mainColor;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid vars.$mainFontGrey;
    margin-bottom: 1rem;
  }

  &__stacks {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__stack {
    margin: 0.5rem 1rem;
  }

  &__valid {
    margin: 1rem 0;
    // border-top: 1px solid vars.$mainFontGrey;
    width: 90%;

    display: flex;
    justify-content: center;
    align-items: center;

    & button {
      margin-top: 1rem;
      background-color: vars.$mainColor;
      border: none;
      color: vars.$mainBackground;
      padding: 8px 1rem;
      border-radius: 4px;
      transition: transform 0.3s;
      margin-bottom: 1rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.dark {
  &.chooseStackContainer {
    background-color: vars.$mainBackgroundformDark;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    &--title {
      color: vars.$mainColor;
      border-bottom: 1px solid vars.$mainFontGrey;
    }

    &__stacks {}

    &__stack {
      color: vars.$mainFontSecondaryDark;
    }

    &__cancel {
      &--button {
        background-color: vars.$cancelOptionDark;
        color: vars.$mainBackground;

        &:hover {
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}