@use '../../styles/vars';

.contact__form {
  transition: 0.2s;
  background-color: vars.$mainBackground;
  margin: 0 auto;
  padding: 1em 3em;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--title {
    margin: 1rem auto;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: vars.$mainColor;
    margin-bottom: 1.5em;
  }

  &--input {
    background-color: vars.$mainBackground;
    border: solid 1px vars.$mainColor;
    width: 100%;
    height: 4.1rem;
    padding: 1.5rem;
    position: relative;
    margin-bottom: 1rem;
    border-radius: 8px;

    &.textarea {
      height: 100%;

      .form-control {
        width: 100%;
        height: 100%;
      }
    }
  }

  &--control {
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-top: -0.5rem;
    padding: 0;
    border: none;
    font-size: 1.2rem;
    color: vars.$mainFontAnthra;
  }

  &--button {
    border: none;
    background-color: vars.$mainColor;
    color: vars.$mainBackground;
    padding: 12px 3rem;
    border-radius: 8px;
    font-size: 1.2rem;
    transition: transform 0.3s;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 1rem;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

    }
  }

  &--captcha {
    text-align: center;
    color: vars.$mainColor;
    font-size: 2rem;
    margin-bottom: 0.5rem;

    &:hover {
      font-weight: bold;
    }
  }
}

.dark {
  transition: 0.2s;

  &.contact__form {
    background-color: vars.$mainBackgroundDark;

    &--title {
      color: vars.$mainColor;
    }

    &--input {
      background-color: vars.$mainBackground;
      border: solid 1px vars.$mainColor;
    }

    &--control {
      color: vars.$mainFontAnthra;
    }

    &--button {
      background-color: vars.$mainColor;
      color: vars.$mainBackground;
    }

    &--captcha {
      color: vars.$mainColor;
    }
  }

  &.inscription__form__buttons__button--cancel {
    background-color: vars.$cancelOptionDark;
  }
}