@use '../../../styles/vars';

.homedeveloper {
  transition: 0.2s;
  background-color: vars.$mainBackground;
  margin: 0 auto;
  padding: 1em 3em;
  min-height: 80vh;

  &__title {
    margin: 1rem auto;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: vars.$mainColor;
    margin-bottom: 1.5em;
  }

  &__content {
    // padding: 0em 3em;
  }

  &__slogan {
    margin-top: 1.8rem;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: vars.$mainFontAnthra;
    font-style: italic;
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--login {
      border: none;
      background-color: vars.$mainColor;
      color: vars.$mainBackground;
      padding: 12px 3rem;
      border-radius: 8px;
      font-size: 1.2rem;
      transition: transform 0.3s;
      margin-bottom: 1.5rem;
      min-width: 17rem;
      max-width: 17rem;

      @media (max-width: 768px) {
        display: none;
      }

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }

    }

    &--inscription {
      border: none;
      background-color: vars.$mainColor;
      color: vars.$mainBackground;
      padding: 12px 3rem;
      border-radius: 8px;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
      transition: transform 0.3s;
      min-width: 17rem;
      max-width: 17rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }
    }

    &--back {
      border: none;
      background-color: vars.$cancelOption;
      color: vars.$mainBackground;
      padding: 12px 3rem;
      border-radius: 8px;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
      transition: transform 0.3s;
      min-width: 17rem;
      max-width: 17rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }
    }
  }
}

.dark {
  transition: 0.2s;

  &.homedeveloper {
    background-color: vars.$mainBackgroundDark;

    &__title {
      color: vars.$mainColor;
    }

    &__dev--content {
      & p {
        color: vars.$mainFontSecondaryDark;
      }

      & .homedeveloper__slogan {
        color: vars.$mainColorDark;
      }

      // padding: 0em 3em;
    }


    &__button {
      &--login {

        background-color: vars.$mainColor;
        color: vars.$mainBackground;
      }

      &--inscription {
        background-color: vars.$mainColor;
        color: vars.$mainBackground;
      }

      &--back {
        background-color: vars.$cancelOptionDark;
        color: vars.$mainBackground;

      }
    }
  }
}



p {
  margin-bottom: 1.7rem;
}

.homedeveloper__dev--content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homedeveloper__dev--content .dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: vars.$mainFontSecondaryDark;
}



.homedeveloper__dev--content p {
  padding: 0;
  max-width: 50rem;
}