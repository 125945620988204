@use '../../styles/vars';

.wrapper {
  transition: 0.2s;
  background-color: vars.$mainBackground;
  min-height: 80vh;
  margin: 0 auto;
}

.dark {
  transition: 0.2s;

  &.wrapper {
    background-color: vars.$mainBackgroundDark;
  }
}

.banniere {
  transition: 0.2s;
  color: vars.$mainBackground;
  // color: vars.$mainFontAnthra;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  border-bottom: 1px solid vars.$mainFontGrey;
  background-color: vars.$mainColor;
  margin-bottom: 2rem;
}

.search {
  transition: 0.2s;
  background-color: vars.$mainBackground;
  margin: 0 auto;
  padding: 1em 3em;
  // min-height: 80vh;

  @media (max-width: 768px) {
    padding: 1em 1em;
  }

  @media (max-width: 550px) {
    padding: 1em 0em;
  }

  &__title {
    margin: 1rem auto;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: vars.$mainColor;
  }

  &__filter {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem;

    @media (max-width: 520px) {
      padding: 0rem 0em;
    }

    &--button {

      border: none;
      background-color: vars.$mainColor;
      color: vars.$mainBackground;
      padding: 12px 3rem;
      border-radius: 8px;
      font-size: 1.2rem;
      transition: transform 0.3s;
      min-width: 14rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }

    }

    &__champ {
      display: flex;
      justify-content: center;
      height: 45px;
      width: 500px;
      align-items: center;
      margin: 0 auto 3rem;
      font-weight: bold;

      @media (max-width: 520px) {
        width: 350px;
        // flex-direction: column;
        // align-items: center;
      }

      @media (max-width: 350px) {
        width: 320px;
        // flex-direction: column;
        // align-items: center;
      }

      &--label {
        display: flex;
        justify-content: flex-start;
        width: 100px;

      }

      &--input {
        display: flex;
        justify-content: center;
        width: 100px;
        width: 60%;
        height: 100%;
        border: 2px solid vars.$mainColor;
        padding: 0 1rem;
        background-color: vars.$mainBackground;
        border-radius: 8px;

      }

      &--radio {
        display: flex;
        align-items: center;
        width: 60%;
        justify-content: center;

        &--item {
          margin-right: 16px;
        }
      }

    }



  }

}

.dark {
  transition: 0.2s;

  &.search {
    background-color: vars.$mainBackgroundDark;

    &__title {
      color: vars.$mainColor;
    }

    &__filter {
      &--button {
        background-color: vars.$mainColor;
        color: vars.$mainBackground;
      }

      &__champ {
        &--label {
          color: vars.$mainFontSecondaryDark;
        }

        &--input {
          border: 2px solid vars.$mainColor;
          background-color: vars.$mainBackground;
        }

        &--radio {
          &--item {
            color: vars.$mainFontSecondaryDark;
          }
        }
      }
    }
  }
}

.result {
  transition: 0.2s;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 570px) {
    justify-content: center;
  }

  &__champ {
    width: 23%;
    margin: 0 1% 1rem;
    display: flex;
    align-items: center;
    border: 2px solid vars.$mainFontGrey;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    // transition: 0.3s;

    @media (max-width: 1023px) {
      width: 31%;
    }

    @media (max-width: 767px) {
      width: 48%;
    }

    @media (max-width: 570px) {
      width: 95%;
    }

    &:hover {
      cursor: pointer;
      // transform: scale(1.1);
      // transition: transform 0.3s;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }


    &--img {
      width: 4rem;
      margin: 0.6rem;
    }


    &--item {
      // to change the style of each result
    }
  }

}

.dark {
  transition: 0.2s;

  &.result {
    &__champ {
      border: 2px solid vars.$mainFontAnthra;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: vars.$mainBackgroundformDark;

      &--img {}

      &--item {
        color: vars.$mainFontSecondaryDark;
      }
    }

  }
}