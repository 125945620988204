@use '../../styles/vars';

.burgerMenu {
  position: absolute;
  font-display: flex;
  width: 100%;
  display: flex;
  z-index: 1000;
  background-color: rgb(0, 0, 0, 0.7);

  overflow-y: hidden;
  top: 0;
  left: 0;

  &__exit {
    position: absolute;
    left: 15rem;
    top: 1rem;
    z-index: 1001;
    color: vars.$mainColor;
    font-size: 2rem;
    cursor: pointer;
  }

  &__opacity {
    height: 100vh;
    // background-color: rgb(0, 0, 0, 0.7);
    // background-color: red;
    // color: red;
    // width: 12rem;
    flex-grow: 2;

    // overflow-y: hidden;
    // top: 0;
  }

  &__login {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;

    // overflow-y: hidden;
    // top: 0;

    &--mail {
      height: 2.5rem;
      border: 1px solid vars.$mainFontGrey;
      margin-bottom: 0.5rem;
      padding-left: 0.5rem;
      font-size: 1.2rem;
    }

    &--password {
      height: 2.5rem;
      border: 1px solid vars.$mainFontGrey;
      margin-bottom: 0.5rem;
      padding-left: 0.5rem;
      font-size: 1.2rem;
    }

    &--button {
      height: 2.5rem;
      border: 1px solid vars.$mainColor;
      background-color: vars.$mainColor;
      margin-bottom: 1rem;
      padding-left: 0.5rem;
      font-size: 1.2rem;
      text-align: center;
      color: vars.$mainBackground;
    }


  }

  &__items {
    // height: 100vh;
    background-color: vars.$mainBackground;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;

    ;
    width: 14rem;
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);

    &--item {
      color: grey;
      width: 12rem;
      font-size: 1.2rem;
      padding: 0.5rem 1.5rem;

      &:hover {
        // background-color: vars.$mainColor;
        color: vars.$mainColor;

      }
    }

    &--log {
      background-color: vars.$mainBackground;
      color: vars.$mainFontAnthra;
      width: 12rem;
      padding: 0.5rem 0;
      font-size: 1.2rem;
      padding: 0.5rem 1.5rem;
      padding-bottom: 1.5rem;



      &:hover {

        // background-color: vars.$mainColor;
        color: vars.$mainColor;
        cursor: pointer;


        // color: vars.$cancelOption;
      }
    }

  }

}

.dark {

  &.burgerMenu {
    background-color: rgb(0, 0, 0, 0.4);

    &__exit {
      color: vars.$secondaryColorDark;
    }

    &__login {
      &--mail {
        border: 1px solid vars.$mainBackgroundClearDark;
        background-color: vars.$mainBackgroundClearDark;
      }

      &--password {
        border: 1px solid vars.$mainBackgroundClearDark;
        background-color: vars.$mainBackgroundClearDark;
      }

      &--button {
        height: 2.5rem;
        border: 1px solid vars.$mainColor;
        background-color: vars.$mainColor;
        margin-bottom: 1rem;
        padding-left: 0.5rem;
        font-size: 1.2rem;
        text-align: center;
        color: vars.$mainBackground;
      }


    }

    &__items {
      // height: 100vh;
      background-color: vars.$mainBackgroundDark;
      text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);

      &--item {
        color: vars.$mainFontSecondaryDark;

        &:hover {
          // background-color: vars.$mainColor;
          color: vars.$mainColorDark;

        }
      }

      &--log {
        background-color: vars.$mainBackgroundDark;
        color: vars.$secondaryColorDark;


        &:hover {}
      }

    }

  }

}