@use '../../styles/vars';


.profilrecruiter {
  transition: 0.2s;
  margin: 0 auto;
  //* modifié par rapport à l'orignial
  padding: 0em 0em;
  min-height: 80vh;
  background: white;

  &__title {
    color: vars.$mainBackground;
    // color: vars.$mainFontAnthra;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    border-bottom: 1px solid vars.$mainFontGrey;
    background-color: vars.$mainColor;
  }

  &__header {
    display: flex;
    justify-content: stretch;

    @media (max-width: 880px) {
      flex-direction: column;
      align-items: center;
    }

    &__cadre {
      width: 300px;
      height: 300px;
      min-width: 300px;
      min-height: 300px;

      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin: 2rem 3rem;

      @media (max-width: 400px) {
        width: 230px;
        height: 230px;
        min-width: 230px;
        min-height: 230px;

      }

      &--img {
        width: 420px;

        @media (max-width: 400px) {
          width: 320px;

        }
      }
    }

    &__about {
      width: 550px;
      height: 300px;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.2);

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }

      margin: 2rem 2rem 2rem 0rem;
      flex-grow: 2;
      position: relative;


      @media (max-width: 880px) {
        margin: 1rem 0rem 1rem 0rem;
        width: 80%;
        margin-bottom: 2rem;

      }

      @media (max-width: 550px) {
        height: 400px;
        width: 90%;
        margin: 1rem 0.5rem 1rem 0.5rem;

      }

      &--label {
        color: vars.$mainColor;
        border-bottom: 1px solid vars.$mainFontGrey;
        margin: 1rem;
        margin-bottom: 2rem;
        font-weight: bold;
      }

      &__item {
        display: flex;
        justify-content: flex-start;
        // align-items: center;
        margin-bottom: 1rem;

        &--label {
          margin-left: 1rem;
          margin-top: 0.3rem;
          min-width: 8rem;
        }

        &--contact {
          font-size: 1.4rem;
          color: vars.$mainFontAnthra;
          font-weight: bold;

        }


        &--name {
          font-size: 1.7rem;
          color: vars.$mainFontAnthra;
          margin-bottom: 0.3rem;
          text-align: left;
          font-weight: bold;
        }

        &--firms {
          font-size: 1.7rem;
          color: vars.$mainColor;
          font-weight: bold;
        }

        &--city {
          font-size: 1.4rem;
          color: vars.$mainColor;
          font-weight: bold;
          text-align: left;
        }


        &--description {
          max-width: 20rem;
          padding-left: 0;
          text-align: left;
          color: vars.$mainFontAnthra;
          line-height: 1.3rem;
          font-weight: bold;
        }

      }


    }
  }

  &__container {
    display: flex;
    padding: 0rem 3rem;

    @media (max-width: 880px) {
      flex-direction: column;
      align-items: center;
      padding: 0rem 0rem;
    }
  }

  &__informations {
    width: 340px;
    min-width: 340px;
    height: 220px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.2);
    position: absolute;


    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 880px) {
      width: 80%;
      padding: 0rem 0rem;
      margin: 0;
      position: relative;
    }

    @media (max-width: 550px) {
      width: 90%;
      margin: 0 0.5rem;
      font-size: 0.8rem;
    }

    &--label {
      color: vars.$mainColor;
      border-bottom: 1px solid vars.$mainFontGrey;
      margin: 1rem;
      font-weight: bold;
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 1rem;
      margin: 1rem;

      &--label {
        width: 6rem;
        min-width: 6rem;
      }

      &--color {
        color: vars.$mainColor;
        font-weight: bold;
      }
    }

  }

  &__modify {
    margin-top: 5rem;
    // margin-left: 21rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &--form {
      border: none;
      background-color: vars.$mainColor;
      color: vars.$mainBackground;
      padding: 12px 3rem;
      border-radius: 8px;
      font-size: 1.2rem;
      transition: transform 0.3s;
      min-width: 12rem;
      margin-bottom: 2rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }
    }
  }


}

.dark {
  transition: 0.2s;

  &.profilrecruiter {
    background: vars.$mainBackgroundDark;

    &__title {
      color: vars.$mainBackground;
      border-bottom: 1px solid vars.$mainFontGrey;
      background-color: vars.$mainColor;
    }

    &__header {
      &__cadre {
        &--img {}

      }

      &__about {
        &--label {
          color: vars.$mainColor;
          border-bottom: 1px solid vars.$mainFontGrey;
        }

        &__item {
          &--label {
            color: vars.$mainFontSecondaryDark;
          }

          &--contact {
            color: vars.$secondaryColorDark;
          }

          &--name {
            color: vars.$secondaryColorDark;
          }

          &--firms {
            color: vars.$secondaryColorDark;
          }

          &--city {
            color: vars.$secondaryColorDark;
          }

          &--description {
            color: vars.$secondaryColorDark;
          }
        }
      }
    }

    &__container {}

    &__informations {
      &--label {
        color: vars.$mainColor;
        border-bottom: 1px solid vars.$mainFontGrey;
      }

      &__item {
        &--label {
          color: vars.$mainFontSecondaryDark;
        }

        &--color {
          color: vars.$secondaryColorDark;
        }
      }
    }

    &__modify {
      &--form {
        background-color: vars.$mainColor;
      }
    }
  }
}