@use '../../styles/vars';

.profil {
    background-color: vars.$mainBackground;
    // margin: 0 auto;
    padding: 1em 3em;
    min-height: 80vh;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 1.2rem;

    @media (max-width: 767px) {
        padding: 0px 0px;
    }



    &__title {
        width: 100%;
        height: 2rem;
        margin-top: 1rem;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        color: vars.$mainColor;

    }

    &__leftpart {
        margin-top: 0;
        width: 50%;
        display: flex;
        flex-Direction: column;
        justify-Content: space-evenly;
        align-Items: center;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &__picture__img {
        width: 10rem;
    }

    &__informations {

        &--name {
            font-size: 1.5rem;
            font-weight: bold;
        }

        &--localisation {
            font-size: 1.5rem;
            font-style: italic;
            margin-bottom: 1rem;
        }

        &--aboutme {
            font-size: 1rem;
        }

        &--description {
            font-size: 1rem;
            font-style: italic;
            margin-bottom: 1rem;
        }

    }

    &__rightpart {
        width: 50%;
        display: flex;
        flex-Direction: column;
        justify-Content: space-evenly;
        align-Items: center;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    &__informations {

        &--checkbox {
            display: flex;
            align-items: center;
        }

        &--visibility {
            padding-left: 1rem;
        }

        &--string {
            text-align: center;
        }

        &--languages {
            display: flex;
            align-items: center;
        }

        &--language {
            padding: 1rem;

            &--picture {
                width: 3rem;
            }
        }

        &--experience {
            margin: 1rem;
        }

        &__github {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 1rem;

            &--img {
                width: 3rem;
            }
        }

        &__othersite {
            display: flex;
            flex-direction: column;
            align-items: center;

            &--img {
                width: 3rem;
            }
        }
    }

    &__modify {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        &__form {
            border: none;
            margin-bottom: 15rem;
            background-color: vars.$mainColor;
            color: vars.$mainBackground;
            padding: 12px 3rem;
            border-radius: 8px;
            font-size: 1.2rem;
            transition: transform 0.3s;

            &:hover {
                transform: scale(1.1);
                transition: transform 0.3s;
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

            }
        }
    }



    // bouton toggle
    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: #2196F3;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}