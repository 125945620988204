@use '../../styles/vars';

.navbar {
  position: sticky;
  top: 0;
  z-index: 3;
}

.header {
  transition: 0.2s;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid vars.$mainColor;
  height: 10vh;
  min-height: 75px;
  padding: 0 3rem;
  color: vars.$mainColor;
  background-color: vars.$mainBackground;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 650px) {
    padding: 0 1rem;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
  }

  &__enSavoirPlus {
    font-size: 1.3rem;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 16rem;

    &--link:after {
      display: block;
      content: '';
      border-bottom: solid 3px vars.$mainColor;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
    }

    &--link:hover:after {
      transform: scaleX(1);
    }

    &fromRight:after {
      transform-origin: 100% 50%;
    }

    &fromLeft:after {
      transform-origin: 0% 50%;
    }
  }

  &__logo {
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 769px) {
      display: none;
    }

    &--img {
      transform: rotate(45deg);
      width: 28px;
      display: block;
      padding-right: 0.5rem;

      @media (max-width: 820px) {
        display: none;
      }


    }
  }

  &__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    color: vars.$mainFontAnthra;
    // display: none;

    &__clearModeIcon {
      margin-left: 1.5rem;
      width: 2rem;
      height: 2rem;

      &:hover {
        cursor: pointer;
      }
    }

    &--item {
      margin: 0 0.6rem;
      font-size: 1.3rem;
      font-weight: bold;
      border-bottom: transparent 3px solid;

      &:after {
        display: block;
        content: '';
        border-bottom: solid 3px vars.$mainColor;
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
      }

      &:hover:after {
        transform: scaleX(1);
      }

      &fromRight:after {
        transform-origin: 100% 50%;
      }

      &fromLeft:after {
        transform-origin: 0% 50%;
      }
    }

    &--item .active {
      border-bottom: vars.$mainColor 3px solid;
    }
  }




  &__button {
    border: none;
    background-color: vars.$mainColor;
    color: vars.$mainBackground;
    padding: 12px 3rem;
    border-radius: 8px;
    font-size: 1.2rem;
    transition: transform 0.3s;
    min-width: 12rem;

    @media (max-width: 550px) {
      margin-bottom: 0.1rem;
    }

    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

    }
  }
}

.dark {
  transition: 0.2s;

  &.header {
    border-bottom: 2px solid vars.$mainColorDark;
    color: vars.$secondaryColorDark;
    background-color: vars.$mainBackgroundDark;

    &__enSavoirPlus {}

    &__logo {
      &--img {}
    }

    &__menu {
      color: vars.$mainFontAnthraDark;

      &__clearModeIcon {
        margin-left: 1.5rem;
        width: 2rem;
        height: 2rem;
      }

      & svg {
        margin-left: 1.5rem;
        width: 2rem;
        height: 2rem;
      }

      &--item {}

      &--item .active {
        border-bottom: vars.$mainColorDark 3px solid;
      }
    }

    &__button {
      background-color: vars.$mainColorDark;
      color: vars.$mainBackgroundDark;
    }
  }
}