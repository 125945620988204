@use '../../styles/vars';

.meetdevWrapper {
  transition: 0.2s;
  background-color: lightgray;
  // background-color: #d6ece6;
  // background: linear-gradient(90deg, rgba(130, 195, 188, 1) 0%, rgba(27, 174, 159, 1) 48%, rgba(130, 195, 188, 1) 100%);
  // position: fixed;
}

.dark {
  &.meetdevWrapper {
    transition: 0.2s;
    background: #112334;
  }
}

.meetdev {
  font-family: 'Comfortaa', cursive;
  max-width: 1024px;
  margin: 0 auto;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}