@use '../../styles/vars';

.mentionslegales {
  transition: 0.2s;
  background-color: vars.$mainBackground;
  margin: 0 auto;
  padding: 1rem 1rem;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;

  &__title {
    margin: 1rem auto;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: vars.$mainColor;
    margin-bottom: 2rem;
  }

  &__subtitle {
    font-size: 1rem;
    font-weight: bold;
    font-style: italic;
    color: vars.$mainColor;
    margin: 1em;
  }
}

.dark {
  transition: 0.2s;

  &.mentionslegales {
    background-color: vars.$mainBackgroundDark;

    &__p {
      color: white;
    }

    &__title {
      color: vars.$mainColor;
    }

    &__subtitle {
      color: vars.$mainColor;
    }

  }
}

p {
  // padding: 0 6em;
  text-align: center;
  margin-bottom: 0.4rem;
}