@use '../../styles/vars';

//* pour le moment car .search englobe tout dans la partie search
//* pour le moment ce composant est affiché dans search
//* ce qui ne devrait plus être le cas prochainement
// .search {
//   margin: 0 auto;
//   //* modifié par rapport à l'orignial
//   padding: 0em 0em;
//   min-height: 80vh;
//   background: white;
// }

.profilDev {
  transition: 0.2s;
  margin: 0 auto;
  //* modifié par rapport à l'orignial
  padding: 0em 0em;
  min-height: 80vh;
  background: white;

  &__title {
    color: vars.$mainBackground;
    // color: vars.$mainFontAnthra;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    border-bottom: 1px solid vars.$mainFontGrey;
    background-color: vars.$mainColor;
  }

  &__header {
    display: flex;
    justify-content: stretch;

    @media (max-width: 880px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__cadre {
      width: 340px;
      height: 300px;
      min-width: 340px;
      min-height: 300px;

      border-radius: 8px;

      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin: 2rem 3rem;
      box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.2);

      @media (max-width: 425px) {
        width: 230px;
        height: 230px;
        min-width: 230px;
        min-height: 230px;

      }

      &--img {
        width: 420px;

        @media (max-width: 400px) {
          width: 320px;

        }
      }
    }

    &__about {
      text-align: center;
      width: 550px;
      height: 300px;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.2);

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }

      margin: 2rem 2rem 2rem 0rem;
      position: relative;

      @media (max-width: 880px) {
        width: 80%;
        margin: 1rem 0rem 2rem 0rem;
        // height: 400px;
      }

      @media (max-width: 450px) {
        width: 340px;
        height: 370px;
        min-width: 250px;
        // min-height: 480px;
      }

      &--favorite {
        margin: auto;
        margin-top: 0.5rem;
        width: 16rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: vars.$mainColor;

        // @media (max-width: 400px) {
        //   right: 3rem;
        //   top: 3rem;
        //   display: none;
        // }

        & img {
          width: 3rem;

          &:hover {
            transform: scale(1.2);
            transition: transform 0.3s;
            filter: opacity(0.4) drop-shadow(0 0 0 rgb(61, 255, 255));
          }
        }

        &:hover {
          cursor: pointer;
          filter: opacity(0.4) drop-shadow(0 0 0 rgb(61, 255, 255));
        }

      }

      &--label {
        text-align: start;
        color: vars.$mainColor;
        border-bottom: 1px solid vars.$mainFontGrey;
        margin: 1rem 1rem 0;
      }

      &--name {
        font-size: 1.7rem;
        font-weight: bold;
        color: vars.$mainFontAnthra;
        margin: 1rem;
      }

      &--pointer {
        width: 1.5rem;
        color: vars.$mainFontGrey;
      }

      &--city {
        color: vars.$mainColor;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      &--profession {
        color: vars.$mainColor;
        font-style: italic;
        margin: 1rem;
      }

      &--citation {
        padding-left: 0;
        text-align: left;
        margin: 1rem;
      }

      &--presentation {
        text-align: center;
        max-width: 35rem;
        margin: 1rem;
        line-height: initial;
      }

      &__mail {
        margin: auto;
        width: 18rem;
        height: 3.5rem;
        border: 2px solid vars.$mainColor;
        border-radius: 8px;
        margin-top: 1.5rem;
        display: flex;
        justify-content: center;
        color: vars.$mainFontAnthra;
        font-size: 1.2rem;
        font-weight: bold;
        padding-top: 1rem;

        &:hover {
          cursor: pointer;
          background-color: vars.$mainColor;
          color: vars.$mainBackground;
        }

        &--logo {
          margin-right: 1rem;
        }

        &--txt {
          padding: 0;
        }

        @media (max-width: 768px) {
          width: 13rem;
          height: 4.2rem;

          &--logo {
            margin-left: 1rem;
          }
        }

        @media (max-width: 425px) {
          width: 13rem;
        }
      }
    }
  }

  &__container {
    display: flex;
    justify-content: center;

    @media (max-width: 1024px) {}

    @media (max-width: 880px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__informations {
    width: 340px;
    min-width: 388px;
    // height: 220px;
    min-height: 250px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.2);

    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 880px) {
      width: 80%;
    }

    @media (max-width: 425px) {
      width: 330px;
      font-size: 0.8rem;
    }

    &--label {
      color: vars.$mainColor;
      border-bottom: 1px solid vars.$mainFontGrey;
      margin: 1rem;
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      margin: 0 0.5rem 1rem;

      &--label {
        width: 6rem;
        min-width: 6rem;
      }

      &--black {}

      &--color {
        color: vars.$mainColor;
        margin: 0 0 0 1rem;
      }
    }

    &__disponibility {
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem;

      &--logo {
        width: 25px;
        margin: 0 1rem;
      }

    }


  }

  &__skills {
    width: 550px;
    // height: 220px;
    min-height: 250px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 3px 3px 15px 3px rgba(0, 0, 0, 0.2);
    margin-left: 3rem;

    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }



    @media (max-width: 880px) {
      width: 80%;
      margin-top: 2rem;
      margin-left: 0;
    }

    @media (max-width: 450px) {
      height: 500px;
      width: 330px;
      font-size: 0.8rem;
      margin-left: 0;
    }

    &--label {
      color: vars.$mainColor;
      border-bottom: 1px solid vars.$mainFontGrey;
      margin: 1rem;
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      margin: 1rem;

      &--label {
        width: 6rem;
        min-width: 6rem;
      }

      &--result {

        &__title {
          margin-bottom: 1rem;
        }
      }

      &--black {}

      &--color {
        color: vars.$mainColor;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: 1rem;

        &--stack {
          padding-right: 0.75rem;
          padding-bottom: 0.5rem;
        }
      }

      &--container {
        display: flex;
        align-items: center;
        width: 100%;
      }

      &__logos {
        margin-left: 6rem;
        width: 100%;
        height: 10rem;
        // max-width: 10rem;
        display: flex;
        flex-wrap: wrap;

        justify-content: flex-start;
        align-items: flex-start;



        &--logo {
          display: flex;
          flex-wrap: wrap;
          width: 3rem;
          height: 3rem;
          margin-left: 1rem;
        }
      }
    }


  }

  &__buttons {
    margin-top: 2.2rem;
    display: flex;
    justify-content: center;

    &--button {
      border: none;
      background-color: vars.$mainColor;
      color: vars.$mainBackground;
      padding: 12px 3rem;
      border-radius: 8px;
      font-size: 1.2rem;
      transition: transform 0.3s;
      min-width: 12rem;
      margin-bottom: 2rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }
    }
  }
}

.dark {
  transition: 0.2s;

  &.profilDev {
    background: vars.$mainBackgroundDark;

    &__title {
      color: vars.$mainBackground;
      border-bottom: 1px solid vars.$mainFontGrey;
      background-color: vars.$mainColor;
    }

    &__header {
      &__cadre {
        background-color: vars.$mainBackgroundformDark;

        &--img {}
      }


      &__about {
        &--favorite {}

        &--label {
          color: vars.$mainColor;
          // background-color: red;
        }

        &--name {
          color: vars.$mainFontGrey;
        }

        &--pointer {
          color: vars.$mainColor;
        }

        &--city {
          color: vars.$mainColor;
        }

        &--profession {
          color: vars.$secondaryColorDark;
        }

        &--citation {}

        &--presentation {
          color: vars.$mainBackgroundClearDark;
        }

        &__mail {
          color: vars.$mainFontAnthra;

          &--logo {
            color: vars.$mainFontSecondaryDark;
          }

          &--txt {
            color: vars.$mainFontSecondaryDark;
          }
        }
      }
    }

    &__informations {
      &--label {
        color: vars.$mainColor;
        border-bottom: 1px solid vars.$mainFontGrey;
      }

      &__item {
        &--label {
          color: vars.$mainFontSecondaryDark;
        }

        &--black {
          color: vars.$mainFontSecondaryDark;
        }

        &--color {
          color: vars.$secondaryColorDark;
        }
      }

      &__disponibility {
        &--txt {
          color: vars.$mainFontSecondaryDark;
        }

        &--logo {}
      }
    }

    &__skills {
      &--label {
        color: vars.$mainColor;
        border-bottom: 1px solid vars.$mainFontGrey;
      }

      &__item {
        &--label {
          color: vars.$mainFontSecondaryDark;
        }

        &--result {
          &__title {
            margin-bottom: 1rem;
          }
        }

        &--black {}

        &--color {
          color: vars.$secondaryColorDark;

          &--stack {
            color: vars.$secondaryColorDark;
          }
        }

        &--container {}

        &__logos {
          &--logo {}
        }
      }
    }

    &__buttons {
      &--button {
        background-color: vars.$mainColor;
        color: vars.$mainBackground;
      }
    }
  }
}