@use '../../../styles/vars';

.card {
  transition: 0.2s;
  // background-color: red;
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  // width: 22.5%;
  max-width: 13rem;
  margin-bottom: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: vars.$mainBackground;
  border-radius: 8px;

  @media (max-width: 1020px) {
    width: 28%;

  }

  @media (max-width: 767px) {
    width: 45%;

  }

  @media (max-width: 560px) {
    width: 60%;

  }



  &__avatar {
    width: 13rem;
    height: 13rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: rgb(31, 209, 191);

    &--img {
      width: 19rem;
    }
  }

  &__container {
    margin: 0.5rem;
    padding: 2px 2px;
    text-align: center;

    &--name {
      padding: 0.2rem;
      color: vars.$mainFontAnthra;
    }

    &--localisation {
      padding: 0.2rem;
      color: vars.$mainFontGrey;
      font-weight: bolder;
      margin-bottom: 0.7rem;

      &--icon {
        width: 1rem;
      }
    }

    &--experience {
      padding: 0.2rem;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &--title {
        width: 100%;
        margin-bottom: 0.3rem;
      }

      &--year {
        border: none;
        background-color: vars.$mainColor;
        color: vars.$mainBackground;
        padding: 4px 1rem;
        border-radius: 8px;

      }
    }
  }

  &__logos {
    display: flex;
    padding: 0.5rem;
    height: 5rem;
    overflow-x: auto;
    height: auto;
    min-height: 4rem;
    margin-bottom: 0.5rem;

    z-index: 1;
    // top: 4px;
    // left: 4px;

    & img {
      width: 1.5rem;
      height: 1.5rem;
      margin: 0.3rem 0.3rem;
    }

  }

  &__button {
    display: flex;
    align-items: center;
    flex-direction: column;

    &--profileview {
      border: none;
      background-color: vars.$mainColor;
      color: vars.$mainBackground;
      padding: 8px 1.2rem;
      border-radius: 8px;
      font-size: 1.2rem;
      margin-top: 0.1rem;
      margin-bottom: 0.5rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }

    }

    &--delete {
      border: none;
      background-color: vars.$mainColor;
      color: vars.$mainBackground;
      padding: 8px 1.2rem;
      border-radius: 8px;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      background-color: vars.$cancelOption;

      &:hover {
        background-color: vars.$cancelOption;
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }

    }
  }
}

.dark {
  transition: 0.2s;

  &.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: vars.$mainBackgroundClearDark;

    &__avatar {
      background-color: vars.$secondaryColorDark;

      &--img {}
    }

    &__container {
      &--name {
        color: vars.$mainFontAnthra;
      }

      &--localisation {
        color: vars.$mainBackgroundDark;

        &--icon {}
      }

      &--experience {
        &--title {}

        &--year {
          background-color: vars.$mainColor;
          color: vars.$mainBackground;
        }
      }
    }

    &__logos {
      & img {}
    }

    &__button {
      &--profileview {
        background-color: vars.$mainColor;
        color: vars.$mainBackground;

        &:hover {
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }
      }

      &--delete {
        background-color: vars.$mainColor;
        color: vars.$mainBackground;
        background-color: vars.$cancelOptionDark;

        &:hover {
          background-color: vars.$cancelOption;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* Add some padding inside the card container */