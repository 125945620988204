@use '../../styles/vars';

.modalLoginBackground {
  transition: 0.2s;
  transition: 0.2s;
  background-color: rgb(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;

  overflow-y: hidden;
  z-index: 1000;

  &.dark {
    background-color: rgb(0, 0, 0, 0.4);
  }
}

.modalLoginContainer {
  transition: 0.2s;
  z-index: 1001;
  margin-top: 15rem;
  // position: relative;
  background-color: vars.$mainBackground;
  border-radius: 0.8rem;
  width: 45rem;
  height: 30rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.5rem;

  overflow-y: hidden;

  @media (max-width: 665px) {
    padding: 1.5rem 0.5rem;
    width: 100%;
  }

  &__header {
    position: relative;
    //display: flex;
    justify-content: center;
    height: 4rem;
    border-bottom: 1px solid vars.$mainFontGrey;
    width: 100%;
    display: inline-grid;

    @media (max-width: 500px) {
      justify-content: space-around;
    }


    &--title {
      color: vars.$mainColor;
      font-size: 1.5rem;
      text-align: center;
    }


    &--button {
      top: -0.8rem;
      right: 2rem;
      position: absolute;
      border: none;
      background-color: vars.$cancelOption;
      padding: 4px 4px;
      color: vars.$mainBackground;
      border-radius: 8px;
      font-size: 1.2rem;
      transition: transform 0.3s;
      width: 2em;

      @media (max-width: 500px) {
        position: relative;
        right: 0rem;
      }

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }
    }
  }

  &__form {
    padding: 2rem 2rem;
    width: 100%;
    // height: 30rem;
    display: flex;
    flex-direction: column;

    &--login {
      border: none;
      border-bottom: 1px solid vars.$mainFontGrey;
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      font-size: 1.3rem;
    }

    &--password {
      border: none;
      border-bottom: 1px solid vars.$mainFontGrey;
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
      font-size: 1.3rem;
    }

    &--error {
      color: vars.$cancelOption;
      margin-top: 1rem;
      text-align: left;
      padding-left: 0;
    }

    &__remember {

      &--checkbox {
        margin-top: 1rem;
      }
    }

    &--connexion {
      background-color: vars.$mainColor;
      border: none;
      margin-top: 2rem;
      padding: 1rem 0;
      border-radius: 4px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      width: 100%;
      color: vars.$mainBackground;
      font-size: 1.2rem;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }
    }

  }

  &__footer {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;

    @media (max-width: 500px) {
      flex-direction: column;
    }

    p {
      padding: 0;
    }

    &--forget {
      color: vars.$mainColor;
    }

    &--register {
      span {
        color: vars.$mainColor;
      }
    }
  }
}

.dark {
  transition: 0.2s;

  &.modalLoginContainer {
    transition: 0.2s;
    background-color: vars.$mainBackgroundformDark;

    &__header {
      border-bottom: 1px solid vars.$mainFontGrey;

      &--title {
        color: vars.$mainColor;
      }

      &--button {
        background-color: vars.$cancelOptionDark;
        color: vars.$mainBackground;
      }
    }

    &__form {

      &--login {
        border-bottom: 1px solid vars.$mainFontGrey;
      }

      &--password {
        border-bottom: 1px solid vars.$mainFontGrey;
      }

      &--error {
        color: vars.$cancelOption;
      }

      &__remember {
        color: vars.$mainFontSecondaryDark;

        &--checkbox {}
      }

      &--connexion {
        background-color: vars.$mainColor;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
        color: vars.$mainBackground;
      }
    }

    &__footer {
      &--forget {
        color: vars.$mainColor;
      }

      &--register {
        color: vars.$mainFontSecondaryDark;

        span {
          color: vars.$mainColor;
        }
      }
    }
  }
}