@use '../../styles/vars';

.modalBackground {
  transition: 0.2s;
  background-color: rgb(0, 0, 0, 0.7);
  height: 100%;
  // max-height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dark {
  transition: 0.2s;

  &.modalBackground {
    background-color: rgb(0, 0, 0, 0.4);
  }
}

.modalContainer {
  // position: fixed;
  transition: 0.2s;
  background-color: vars.$mainBackground;
  border-radius: 0.8rem;
  width: 45rem;
  height: 45rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 2.5rem;
  z-index: 1001;

  @media (max-width: 665px) {
    padding: 1.5rem 0.5rem;
    width: 100%;
  }




  &__exit {
    display: flex;
    justify-content: flex-end;

    &--button {
      border: none;
      background-color: vars.$cancelOption;
      padding: 4px 4px;
      color: vars.$mainBackground;
      border-radius: 8px;
      font-size: 1.2rem;
      transition: transform 0.3s;
      width: 2em;

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }
    }
  }


  &__header {
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem;
    text-align: center;
    width: 12rem;
    height: 12rem;
    border-radius: 12rem;
    border: 1px solid vars.$mainFontGrey;

    &--avatar {
      width: 100%;
    }
  }

  &__main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: vars.$mainFontAnthra;
    flex-wrap: wrap;

    @media (max-width: 665px) {
      position: relative;
    }



    &--left {
      font-size: 5rem;
      font-weight: bold;

      @media (max-width: 665px) {
        position: absolute;
        left: 65px;
        top: 125px;
        font-size: 3.5rem;
      }

      @media (max-width: 510px) {
        left: 10%;
      }

      // @media (max-width: 560px) {
      //   left: 10%;
      // }

      &:hover {
        cursor: pointer;
      }
    }

    &--right {
      font-size: 5rem;
      font-weight: bold;

      @media (max-width: 665px) {
        position: absolute;
        right: 65px;
        top: 125px;
        font-size: 3.5rem;
      }

      @media (max-width: 510px) {
        right: 10%;
      }

      &:hover {
        cursor: pointer;
      }
    }

    &__profil {
      padding: 1.5rem;
      border-radius: 8px;
      border: 1px solid vars.$mainFontGrey;
      width: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.5rem;
      line-height: 1.3rem;

      @media (max-width: 665px) {
        width: 90%;
      }

      &--item {
        margin-bottom: 0.8em;

        &--name {
          color: vars.$mainColor;
          margin-bottom: 0.8em;
          font-weight: bold;
        }

        &--experience {
          color: vars.$mainColor;
          margin-bottom: 0.8em;
        }

        &--adress {
          display: flex;
          align-items: center;
          font-style: italic;
          color: vars.$mainColor;

          &--icon {
            width: 1.5rem;
            margin: 0;
            padding: 0;
          }




        }

      }

      &--logos {
        margin-top: 0.8rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        overflow-x: auto;

        @media (max-width: 665px) {
          width: 70%;
        }

        @media (max-width: 490px) {
          width: 80%;
        }

        &--logo {
          width: 2.5rem;
          display: block;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    margin-top: 4rem;
    padding: 0 2rem;
    display: flex;
    justify-content: space-evenly;

    @media (max-width: 767px) {

      flex-direction: column;
      align-items: center;
      margin-top: 0.5rem;
    }

    &--button {
      border: none;
      width: 30%;
      background-color: vars.$mainColor;
      color: vars.$mainBackground;
      border-radius: 8px;
      font-size: 1.2rem;
      padding: 8px 30px;
      transition: transform 0.3s;
      width: 14rem;

      @media (max-width: 767px) {
        width: 14rem;
        margin-top: 3rem;
      }

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

      }
    }

    // &--exit {
    //   border: none;
    //   background-color: vars.$cancelOption;
    //   padding: 8px 30px;
    //   color: vars.$mainBackground;
    //   border-radius: 8px;
    //   font-size: 1.2rem;
    // }



  }

}

.dark {
  transition: 0.2s;

  &.modalContainer {
    background-color: vars.$mainBackgroundDark;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;

    &__exit {
      &--button {
        background-color: vars.$cancelOptionDark;
        color: vars.$mainBackground;
      }
    }

    &__header {
      border: 1px solid vars.$mainFontGrey;

      &--avatar {}
    }

    &__main {
      color: vars.$mainFontAnthra;

      &--left {
        color: vars.$mainFontSecondaryDark
      }

      &--right {
        color: vars.$mainFontSecondaryDark
      }

      &__profil {
        border: 1px solid vars.$mainFontGrey;

        &--item {
          color: vars.$mainFontSecondaryDark;

          &--name {
            color: vars.$mainColor;
          }

          &--experience {
            color: vars.$mainColor;
          }

          &--adress {
            color: vars.$mainColor;

            &--icon {}
          }
        }

        &--logos {
          &--logo {}
        }
      }
    }

    &__footer {
      &--button {
        background-color: vars.$mainColor;
        color: vars.$mainBackground;
      }
    }
  }
}